import React from 'react'

interface Props {
    children: string,
}

const BadgesPrimary = (props: Props) => {
    return (
        <div className="m-1.5 shadow px-3 py-1 rounded-md bg-primary text-bg-primary font-semibold">
            {props.children}
        </div>
    )
}

export default BadgesPrimary
