import React from 'react';
import Landing from "./Pages/Landing";
import {Navigate, Route, Routes} from "react-router-dom";
import Error from "./Pages/Error";

function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/500" element={<Error  message="We are working on fixing the problem. please try again"
                                                   title="Something went wrong..." type="500"/>}/>
                <Route path="/404" element={<Error message="The page is missing or you assembled the link incorrectly."
                                                   title="Page Not Found!" type="404"/>}/>
                <Route path="*" element={<Navigate to="/404"/>}/>
            </Routes>
        </>
    );
}

export default App;
