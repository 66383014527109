import React from 'react'
import {Map} from "../Components/Map";

interface Props {

}

const AboutMe = (props: Props) => {
    return (
        <section id="about" className="md:flex md:justify-around px-8 md:py-32 py-16">
            <div className="md:max-w-[45%] text-primary my-auto">
                <h2 className="text-5xl font-black text-primary mb-8">WHO Am I?</h2>
                <p className="my-4">I'm Amin Bitaraf. I live in Tehran.</p>
                <p className="my-4">I'm a tireless seeker of programming. I value honesty, discipline and punctuality
                    above all else and these are my approach to life in general.</p>
                <p className="my-3 mt-8 font-bold">Follow me in Social Media</p>
                <div className="flex flex-wrap">
                    <a href="https://github.com/aminbitaraf" rel="noreferrer" target="_blank"
                       className="flex mb-4 mr-8 underline">
                        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16 22.0001V18.1301C16.0375 17.6532 15.9731 17.1739 15.811 16.7238C15.6489 16.2738 15.3929 15.8635 15.06 15.5201C18.2 15.1701 21.5 13.9801 21.5 8.52006C21.4997 7.12389 20.9627 5.78126 20 4.77006C20.4559 3.54857 20.4236 2.19841 19.91 1.00006C19.91 1.00006 18.73 0.65006 16 2.48006C13.708 1.85888 11.292 1.85888 9 2.48006C6.27 0.65006 5.09 1.00006 5.09 1.00006C4.57638 2.19841 4.54414 3.54857 5 4.77006C4.03013 5.78876 3.49252 7.14352 3.5 8.55006C3.5 13.9701 6.8 15.1601 9.94 15.5501C9.611 15.89 9.35726 16.2955 9.19531 16.74C9.03335 17.1845 8.96681 17.6581 9 18.1301V22.0001M9 19.0001C4 20.5001 4 16.5001 2 16.0001L9 19.0001Z"
                                stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Github
                    </a>
                    <a href="https://www.instagram.com/amin__bitaraf/" rel="noreferrer" target="_blank"
                       className="flex mr-8 mb-4 underline">
                        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17 2H7C4.23858 2 2 4.23858 2 7V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V7C22 4.23858 19.7614 2 17 2Z"
                                stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M16 11.3699C16.1234 12.2022 15.9812 13.0522 15.5937 13.799C15.2062 14.5457 14.5931 15.1513 13.8416 15.5296C13.0901 15.9079 12.2384 16.0395 11.4077 15.9059C10.5771 15.7722 9.80971 15.38 9.21479 14.7851C8.61987 14.1902 8.22768 13.4228 8.09402 12.5921C7.96035 11.7615 8.09202 10.9098 8.47028 10.1583C8.84854 9.40679 9.45414 8.79368 10.2009 8.40618C10.9477 8.01868 11.7977 7.87652 12.63 7.99994C13.4789 8.12582 14.2648 8.5214 14.8716 9.12824C15.4785 9.73508 15.8741 10.521 16 11.3699Z"
                                stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.5 6.5H17.5107" stroke="#3A3A3A" strokeWidth="2" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        Instagram
                    </a>
                    <a href="https://t.me/amin_bitaraf" target="_blank" rel="noreferrer"
                       className="flex mb-4 mr-8 underline">
                        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.24987 12.6443L16.6796 20.0625C16.7772 20.1484 16.8953 20.2074 17.0225 20.2338C17.1497 20.2602 17.2816 20.2532 17.4053 20.2133C17.5289 20.1734 17.6401 20.1021 17.7279 20.0063C17.8157 19.9106 17.8771 19.7937 17.9061 19.667L21.4324 4.27959C21.4629 4.14655 21.4565 4.00771 21.4139 3.87804C21.3713 3.74837 21.2942 3.63277 21.1907 3.54371C21.0873 3.45466 20.9615 3.39551 20.827 3.37265C20.6924 3.3498 20.5542 3.36409 20.4271 3.414L3.12493 10.2113C2.97414 10.2705 2.84658 10.377 2.76135 10.5148C2.67612 10.6526 2.63779 10.8142 2.6521 10.9756C2.66642 11.137 2.7326 11.2894 2.84076 11.41C2.94891 11.5307 3.09322 11.613 3.25208 11.6448L8.24987 12.6443Z"
                                stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.25 12.6443L21.0105 3.42834" stroke="#3A3A3A" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M12.46 16.3491L9.53033 19.2788C9.42544 19.3837 9.2918 19.4551 9.14632 19.4841C9.00083 19.513 8.85003 19.4982 8.71299 19.4414C8.57594 19.3846 8.45881 19.2885 8.3764 19.1652C8.29399 19.0418 8.25 18.8968 8.25 18.7485V12.6443"
                                stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Telegram
                    </a>
                    <a href="https://twitter.com/intent/follow?screen_name=amin__bitaraf" target="_blank"
                       rel="noreferrer" className="flex mr-8 mb-4 underline">
                        <svg className="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M23 3.00005C22.0424 3.67552 20.9821 4.19216 19.86 4.53005C19.2577 3.83756 18.4573 3.34674 17.567 3.12397C16.6767 2.90121 15.7395 2.95724 14.8821 3.2845C14.0247 3.61176 13.2884 4.19445 12.773 4.95376C12.2575 5.71308 11.9877 6.61238 12 7.53005V8.53005C10.2426 8.57561 8.50127 8.18586 6.93101 7.39549C5.36074 6.60513 4.01032 5.43868 3 4.00005C3 4.00005 -1 13 8 17C5.94053 18.398 3.48716 19.099 1 19C10 24 21 19 21 7.50005C20.9991 7.2215 20.9723 6.94364 20.92 6.67005C21.9406 5.66354 22.6608 4.39276 23 3.00005V3.00005Z"
                                stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Tweeter
                    </a>
                </div>
            </div>
            <div className="md:max-w-[535px] max-h-max mt-8 md:mt-0 md:w-[55%] relative">
                <Map/>
                <div className="points bg-primary border-primary"/>
            </div>
        </section>
    )
}

export default AboutMe
