import React, {useEffect, useState} from 'react';
import ScreenLoader from "./../Components/Loader/ScreenLoader";
import {Navbar} from '../Components/Navbar/Navbar'
import Skills from './../Sections/Skills';
import Home from "./../Sections/Home";
import AboutMe from "./../Sections/AboutMe";
import Contact from "./../Sections/Contact";
import {api} from "../Api/axios";
import group from "../Types/group.interface";
import {useNavigate} from "react-router-dom"

const Landing = () => {
    const [loader, setLoader] = useState(true);
    const [skills, setSkills] = useState<group[]>([]);
    const navigate = useNavigate()
    const getSkills = () => {
        api.get("/api/group").then((res) => {
                if (res.status === 200) {
                    setSkills(res.data.data)
                    setLoader(false)
                } else {
                    navigate('/500?to=/')
                }
            }
        ).catch(err => {
            navigate('/500?to=/')
        });
    };

    useEffect(() => {
        getSkills()
    }, [])

    return (
        <>
            <ScreenLoader show={loader}/>
            <div className="font-sans bg-bg-primary select-none">
                <Navbar/>
                <div className="container mx-auto">
                    <Home/>
                    <Skills skills={skills}/>
                    <AboutMe/>
                </div>
                <Contact/>
            </div>
        </>
    );
};

// @ts-ignore
export default Landing