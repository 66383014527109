import React from 'react';
import ReactDOM from 'react-dom';
import './Assets/css/index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {QueryParamProvider} from "use-query-params";

ReactDOM.render(
    <BrowserRouter>
        <QueryParamProvider>
            <App/>
        </QueryParamProvider>
    </BrowserRouter>,
    document.getElementById('root'));
