import React from 'react'

interface Props {
    children: string,
    isDark: boolean,
    isActive: boolean,
    handleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, section: string) => void
}

export const Item = (props: Props) => {
    const darkTheme = `${props.isActive ? 'text-primary bg-bg-primary opacity-100' : 'hover:text-primary hover:bg-bg-primary opacity-70 hover:opacity-100 text-bg-primary'}`;
    const lightTheme = `${props.isActive ? 'text-bg-primary bg-primary opacity-100' : 'hover:text-bg-primary hover:bg-primary opacity-70 hover:opacity-100 text-primary'}`;

    return (
        <div
            onClick={(e) => props.handleClick(e, props.children)}
            className={`transition-colors px-4 py-2 m-4 cursor-pointer rounded-md font-medium text-xl
             ${props.isDark ? darkTheme : lightTheme}`}>
            {props.children}
        </div>
    )
}
