import React from 'react';

interface Props {
    show: boolean
}

const ScreenLoader = (props: Props) => {
    return (
        <div className={`${props.show ? "fixed" : "hidden"} top-0 left-0 min-h-[100vh] min-w-[100vw] bg-primary z-50`}>
            <div className="loading-screen my-auto">
                <div className="loader-wrap">
                    <span className="loader-animation"/>
                    <div className="loading-text">
                        <span className='letter'>L</span>
                        <span className='letter'>O</span>
                        <span className='letter'>A</span>
                        <span className='letter'>D</span>
                        <span className='letter'>I</span>
                        <span className='letter'>N</span>
                        <span className='letter'>G</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScreenLoader;