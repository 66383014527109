import React from "react";
import group from "../../Types/group.interface";
import BadgesPrimary from "../Badges/Primary";

interface Props {
    group: group;
}

const FlatCard = (props: Props) => {
    return (
        <div className="flex flex-col items-start max-w-[18rem] m-auto mt-0 p-3">
            <div className="image-shadow h-[13rem] flex w-full">
                <img
                    className="max-w-[11rem] m-auto"
                    src={`data:image/png;base64,${props.group.image}`}
                    alt={props.group.name}
                />
            </div>
            <h1 className="text-xl tracking-tight font-extrabold text-primary sm:text-2xl md:text-2xl mx-auto mt-5 mb-2">{props.group.name}</h1>
            <ul className="flex flex-wrap">
                {props.group.skills.map((item) => {
                    return <BadgesPrimary key={item.id}>{item.name}</BadgesPrimary>;
                })}
            </ul>
        </div>
    );
};

export default FlatCard;
