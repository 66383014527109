import React from 'react';

const Placeholder = () => {
    return (
        <div className="animate-pulse max-w-[18rem] w-full mt-4 p-3 bg-gray-300 rounded text-bg-primary flex flex-col items-start mx-auto">

        </div>
    );
};

export default Placeholder;