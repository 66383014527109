import React from 'react';
import {UseFormRegisterReturn} from 'react-hook-form';

interface Props {
    label: string,
    textarea?: boolean,
    className?: string,
    error?: any,
    placeholder?: string,
    register: UseFormRegisterReturn
}

const Input = (props: Props) => {
    const inputClass = "placeholder:text-[#EFEFEF] rounded placeholder:opacity-80 w-full outline-none p-3 pt-4 bg-primary" +
        " border-bg-primary border-2 text-[#FFFFFF] -mt-3";

    return (
        <div className={`w-full ${props.className}`}>
            <label
                className={`block translate-x-2 translate-y-1.5 font-medium text-lg max-w-fit text-bg-primary bg-primary py-1 px-2
                 ${props.error ? 'text-red-400' : ''}`}
                htmlFor={props.register.name}>{props.label}</label>
            {
                props.textarea ?
                    <textarea {...props.register} className={`${inputClass} ${props.error ? 'border-red-400' : ''} -mb-2 resize-none`}
                              placeholder={props.placeholder}
                              name={props.register.name} id={props.register.name} rows={6}>
                    </textarea> :
                    <input
                        {...props.register}
                        placeholder={props.placeholder}
                        className={`${inputClass} ${props.error ? 'border-red-400' : ''}`}
                        name={props.register.name} id={props.register.name} autoComplete="off"/>
            }
            {props.error && props.error.type !== 'required' ?
                <p className="text-red-400 pt-1">{props.error.message}</p>
                : ''}
        </div>
    );
};

export default Input;