import React, {useEffect} from 'react';
// @ts-ignore
import MyImage from "../Assets/images/AminBitaraf.png";
// @ts-ignore
import handImg from "../Assets/images/hand.png";
import {Button} from "../Components/Buttons";
import {useLocalStorage} from "../Hooks/useLocalStorage";


const Home = () => {
    const [showTextDrag, setShowTextDrag] = useLocalStorage('showTextDrag', true)

    const goToContact = () => {
        const contactPageYOffset = document.getElementById('contact')?.offsetTop;
        window.scrollTo({top: contactPageYOffset, behavior: 'smooth'})
    }

    useEffect(function () {
        window.addEventListener('pointermove', e => {
            const zoom: HTMLDivElement | null = document.querySelector('.my-image > .layer');
            const image: HTMLDivElement | null = document.querySelector('.my-image');
            if (zoom != null && image !== null) {
                zoom.style.setProperty('--mouse-x', e.clientX - image.offsetLeft + 'px')
                zoom.style.setProperty('--mouse-y', (e.clientY + window.pageYOffset) - image.offsetTop + 'px')
                if (((e.clientY + window.pageYOffset) - image.offsetTop > 0) &&
                    ((e.clientY + window.pageYOffset) - image.offsetTop < image.offsetHeight) &&
                    ((e.clientX + window.pageYOffset) - image.offsetTop > 0) &&
                    ((e.clientX + window.pageYOffset) - image.offsetTop < image.offsetWidth)
                ) {
                    setShowTextDrag(false)
                } else {
                    setShowTextDrag(true)
                }
            }
        })
    }, [])

    return (
        <section className="md:flex-row flex justify-around flex-col-reverse mx-8 pt-4 pb-8 md:py-16">
            <div className="my-auto">
                <div className="mb-6">
                    <h2 className="sm:text-5xl text-4xl flex font-black text-primary mb-1">Hi, I’m Amin
                        <img src={handImg} className="ml-3 sm:max-h-12 max-h-9 my-auto mr-auto wave-hand" alt=""/>
                    </h2>
                    <h3 className="text-primary/50 text-xl font-bold">Fullstack Developer</h3>
                </div>
                <p className="my-2 text-lg text-primary">I have a passion in solving complex problems.</p>
                <p className="my-2 text-lg text-primary">I believe in creating experiences beyond the limitations.</p>
                <p className="my-2 text-lg text-primary">Let's make something special. Just contact me :)</p>
                <Button className="mt-8 shadow-md" handleClick={goToContact} type="button" theme="primary">Send
                    Message</Button>
            </div>
            <div className="my-image md:w-[55%] w-full md:mx-0 mx-auto md:mb-0 mb-8">
                <img src={MyImage} alt=""/>
                <img className="layer" src={MyImage} alt="Amin Bitaraf"/>
                {
                    window.innerWidth < 768 ?
                        <div
                            className={`${showTextDrag ? '' : 'translate-y-full'} transition-transform duration-500 image-footer absolute bottom-0 w-full text-center text-white text-lg bg-primary/50 p-2`}>
                            Touch image for amazing!
                        </div> : ''
                }
            </div>
        </section>
    )
}

export default Home
