import React, {useState} from 'react'
import Input from "../Components/Inputs";
import {Button} from "../Components/Buttons";
import {api} from "../Api/axios";
import Alert from "../Components/Alerts";
import {useForm} from "react-hook-form";

const Contact = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        show: false,
        type: 'success',
        message: ''
    });

    const postMessage = (data: any) => {
        setLoading(true)
        setTimeout(() => {
            api.post('/api/message', data).then(r => {
                if (r.status === 200) {
                    setAlert({
                        show: true,
                        type: 'success',
                        message: r.data.message
                    })
                    reset()
                    setTimeout(() => {
                        setAlert(prevState => ({
                            ...prevState,
                            show: false,
                        }))
                    }, 5000)
                } else {
                    setAlert({
                        show: true,
                        type: 'error',
                        message: "unfortunately, your message was failed to send! Try again later."
                    })
                    setTimeout(() => {
                        setAlert(prevState => ({
                            ...prevState,
                            show: false,
                        }))
                    }, 5000)
                }
                setLoading(false)
            }).catch(error => {
                setAlert({
                    show: true,
                    type: 'error',
                    message: "unfortunately, your message was failed to send! Try again later."
                })
                setTimeout(() => {
                    setAlert(prevState => ({
                        ...prevState,
                        show: false,
                    }))
                }, 5000)
                setLoading(false)
            })
        }, 1000)

    }

    return (
        <section id="contact" className="min-h-screen bg-primary">
            <div className="relative flex justify-center items-center min-h-screen mx-auto p-8 md:p-24">
                <form onSubmit={handleSubmit(postMessage)} className="w-[44rem]">
                    <h1 className="text-5xl mb-4 font-bold text-bg-primary">Send
                        Message</h1>
                    <div className="md:flex">
                        <Input register={register('first_name', {
                            required: true,
                            minLength: {
                                value: 3,
                                message: 'First Name must be between 3-24 characters.'
                            },
                            maxLength: {
                                value: 24,
                                message: 'First Name must be between 3-24 characters.'
                            }
                        })} error={errors.first_name} label={"First Name"}
                               className="md:mr-2" placeholder={'ex: Amin'}/>
                        <Input register={register('last_name', {
                            required: true,
                            minLength: {
                                value: 3,
                                message: 'Last Name must be between 3-24 characters.'
                            },
                            maxLength: {
                                value: 24,
                                message: 'Last Name must be between 3 and 24 characters.'
                            },
                        })} error={errors.last_name} label={"Last Name"}
                               className="md:ml-2" placeholder={'ex: Bitaraf'}/>
                    </div>
                    <Input register={register('email', {
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address!"
                        }
                    })} error={errors.email} placeholder={'ex: example@domain.com'}
                           label={"Email"}/>
                    <Input register={register('message', {
                        required: true,
                        minLength: {
                            value: 8,
                            message: 'Message must be between 8-400 characters.'
                        },
                        maxLength: {
                            value: 400,
                            message: 'Message must be between 8-400 characters.'
                        },
                    })} error={errors.message} textarea={true} placeholder={'ex: I have new idea...'}
                           label={"Message"}/>
                    <Button type='submit' theme={'seconder'}
                            className={'w-full mt-4 text-xl flex justify-center items-center'}>
                        {
                            loading ?
                                <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 stroke-primary fill-primary"
                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" fill="none"
                                                strokeWidth="4"/>
                                        <path className="opacity-75"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                                    </svg>
                                    Loading...
                                </>
                                : "Send"
                        }
                    </Button>
                </form>

                <button onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                        className="absolute md:block hidden animate-bounce bottom-16 right-16 p-2 rounded bg-bg-primary border-primary border-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M25.333 21.2842L16.0488 11.9999L6.76455 21.2842" stroke="#3A3A3A" strokeWidth="2"
                              strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            <Alert message={alert.message} show={alert.show} type={alert.type} handleClick={event => {
                setAlert(prevState => ({
                    ...prevState,
                    show: false
                }))
            }}/>
        </section>
    )
}

export default Contact
