import React, {useEffect, useState} from 'react'
import {Item} from './Item'

interface Props {

}

const NavItems = ['Home', 'My Skills', 'About Me', 'Contact']

export const Navbar = (props: Props) => {
    const scrollspy = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, section: string): void => {
        const skillsPageYOffset = document.getElementById('skills')?.offsetTop
        const aboutPageYOffset = document.getElementById('about')?.offsetTop
        const contactPageYOffset = document.getElementById('contact')?.offsetTop

        if (openMenu) {
            setOpenMenu(false)
        }

        if (section === NavItems[0]) {
            window.scrollTo({top: 0, behavior: 'smooth'})
        } else if (section === NavItems[1] && skillsPageYOffset) {
            window.scrollTo({top: skillsPageYOffset - 100, behavior: 'smooth'})
        } else if (section === NavItems[2] && aboutPageYOffset) {
            window.scrollTo({top: aboutPageYOffset - 78, behavior: 'smooth'})
        } else if (section === NavItems[3] && contactPageYOffset) {
            window.scrollTo({top: contactPageYOffset, behavior: 'smooth'})
        }
    }
    const lightClass = "border-bg-primary bg-bg-primary/60 firefox:bg-95 firefox:bg-bg-primary";
    const darkClass = "border-primary bg-primary/60 firefox:bg-95 firefox:bg-primary";

    const [navTheme, setNavTheme] = useState({
        isDark: false
    })

    const [activePage, setActivePage] = useState({
        page: NavItems[0]
    })

    const [openMenu, setOpenMenu] = useState(false)

    useEffect(function () {
        const document = window.document;

        const changeNavTheme = (): void => {
            const skillsPageYOffset = document.getElementById('skills')?.offsetTop
            const aboutPageYOffset = document.getElementById('about')?.offsetTop
            const contactPageYOffset = document.getElementById('contact')?.offsetTop

            if (contactPageYOffset && window.pageYOffset >= contactPageYOffset - 78) {
                setNavTheme({
                    isDark: true
                })
            } else {
                setNavTheme({
                    isDark: false
                })
            }
            if (skillsPageYOffset && window.pageYOffset < skillsPageYOffset - 100) {
                setActivePage({
                    page: NavItems[0]
                })
            } else if (aboutPageYOffset && window.pageYOffset < aboutPageYOffset - 78) {
                setActivePage({
                    page: NavItems[1]
                })
            } else if (contactPageYOffset && window.pageYOffset < contactPageYOffset - 78) {
                setActivePage({
                    page: NavItems[2]
                })
            } else {
                setActivePage({
                    page: NavItems[3]
                })
            }
        }

        document.addEventListener('scroll', changeNavTheme)
        return function cleanup() {
            document.removeEventListener('scroll', changeNavTheme)
        }
    }, [])

    return (
        <>
            <nav
                className={`${navTheme.isDark ? darkClass : lightClass} md:flex hidden transition-colors duration-500 backdrop-blur-md z-40 border-b-2 justify-center sticky left-0 right-0 top-0`}>
                {
                    NavItems.map(item => {
                        return <Item handleClick={scrollspy} isActive={activePage.page === item}
                                     isDark={navTheme.isDark}
                                     key={item}>{item}</Item>
                    })
                }
            </nav>
            <nav
                className={`${navTheme.isDark || openMenu ? darkClass : lightClass} md:hidden sticky top-0 left-0 right-0 transition-colors duration-500 backdrop-blur-md z-40 border-b-2 h-20 relative`}>
                <div
                    onClick={() => setOpenMenu(prevState => !prevState)}
                    className={`nav-menu ${openMenu ? "open" : ''} ${navTheme.isDark ? "dark" : ""} flex flex-col justify-around absolute top-6 left-8 z-20 h-8 w-8`}/>
            </nav>
            <div
                className={`${openMenu ? 'translate-x-0 open' : '-translate-x-full'} side-menu flex flex-col justify-center items-center z-10 transition-all ease-out duration-500 fixed bg-primary top-0 left-0 right-0 bottom-0`}>
                {
                    NavItems.map(item => {
                        return <div key={item} onClick={(e) => scrollspy(e, item)}
                                    className="py-8 -translate-x-12 transition duration-700 opacity-0 invisible ease text-bg-primary text-3xl font-bold">
                            {item}
                        </div>
                    })
                }
            </div>
        </>
    )
}
