import {useState} from "react"

const useLocalStorage = (name: string, initialValue: any) => {
    const [storageValue, setStorageValue] = useState(() => {
        const valFromStorage = localStorage.getItem(name)
        return valFromStorage ? JSON.parse(valFromStorage) : initialValue
    })

    const setValue = (value: string) => {
        setStorageValue(value)
        localStorage.setItem(name, JSON.stringify(value))
    }

    return [storageValue, setValue]
}

export {useLocalStorage}