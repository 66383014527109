import React from "react";

interface Props {
    children?: JSX.Element | JSX.Element[] | string,
    type: string,
    theme: string,
    className?: string,
    handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const Button = (props: Props) => {
    const primaryBtn = "bg-primary text-bg-primary";
    const seconderBtn = "bg-bg-primary text-primary";
    const baseBtn = "rounded-sm py-3 px-6 font-bold";

    return <button onClick={event => {
        if (props.handleClick) {
            props.handleClick(event)
        }
    }}
                   className={`${props.className} ${(props.theme === 'primary' ? primaryBtn : seconderBtn)} ${baseBtn}`}>
        {props.children}
    </button>
}