import React from 'react';
import {Link} from "react-router-dom";
import {Button} from "../Components/Buttons";
import {StringParam, useQueryParam} from "use-query-params";

interface Props {
    type: string,
    title: string,
    message: string,
}

export default function Error(props: Props) {
    const [to] = useQueryParam('to', StringParam)

    return (
        <div className="p-4 bg-bg-primary min-h-screen flex-col flex justify-center">
            <h1 className="text-9xl font-black shimmer">{props.type}</h1>
            <h3 className="text-center text-3xl mt-16 text-primary font-bold">{props.title}</h3>
            <p className="text-center my-8 text-lg">{props.message}</p>
            {
                props.type === '500' ?
                    <Link className="text-center" to={to ?? '/'}><Button className="flex m-auto mt-8 text-indigo-600"
                                                                         theme="secondary"
                                                                         type="button">
                        <svg className="stroke-indigo-600 mr-4" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="COCO/Line/Rotate-Right">
                                <path id="Vector"
                                      d="M21 12.1525C21 17.0389 16.9706 21 12 21C7.02944 21 3 17.0389 3 12.1525C3 7.26622 7 3.30508 12 3.30508C18 3.30508 21 8.22034 21 8.22034M21 8.22034L21 3M21 8.22034H16.3449"
                                      strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </g>
                        </svg>
                        <p className="my-auto">Refresh Page</p>
                    </Button></Link>
                    :
                    <Link className="text-center" to="/"><Button className="flex m-auto mt-8 text-indigo-600"
                                                                 theme="secondary"
                                                                 type="button">
                        <svg className="stroke-indigo-600 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g id="COCO/Line/Arrow-Left">
                                <path id="Vector" d="M17.5 12L6.5 12M6.5 12L11.0882 16M6.5 12L11.0882 8"
                                      strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </g>
                        </svg>
                        <p>Go to Landing Page</p></Button></Link>
            }
        </div>
    );
}
