import React from 'react';

interface Props {
    message: string,
    type: string,
    show: boolean,
    handleClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Alert = (props: Props) => {
    const hideClass = "-translate-x-8 opacity-0 invisible";
    const showClass = "translate-x visible  opacity-100";
    const error = "border-red-600 bg-red-100 text-red-600 stroke-red-600 fill-red-600";
    const success = "border-emerald-700 bg-emerald-100 text-emerald-700 stroke-emerald-700 fill-emerald-700";

    return (
        <div
            className={`${props.show ? showClass : hideClass} ${props.type === 'success' ? success : error} 
            alert-transitions fixed z-50 px-4 
            flex justify-between items-start py-3 sm:w-96 border-2 
            rounded md:bottom-16 md:left-16 bottom-8 left-4 right-4`}>
            {
                props.type === 'success' ?
                    <svg className="min-w-[28px]" width="28" height="28" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="COCO/Line/Check">
                            <path id="Vector"
                                  d="M3.35288 8.95043C4.00437 6.17301 6.17301 4.00437 8.95043 3.35288C10.9563 2.88237 13.0437 2.88237 15.0496 3.35288C17.827 4.00437 19.9956 6.17301 20.6471 8.95044C21.1176 10.9563 21.1176 13.0437 20.6471 15.0496C19.9956 17.827 17.827 19.9956 15.0496 20.6471C13.0437 21.1176 10.9563 21.1176 8.95044 20.6471C6.17301 19.9956 4.00437 17.827 3.35288 15.0496C2.88237 13.0437 2.88237 10.9563 3.35288 8.95043Z"
                                  strokeWidth="1.5"/>
                            <path id="Vector_2" d="M9.25 11.75L11.25 13.75L14.75 10" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
                    :
                    <svg className="min-w-[28px]" width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="COCO/Line/Close">
                            <path id="Vector"
                                  d="M3.35288 8.95043C4.00437 6.17301 6.17301 4.00437 8.95043 3.35288C10.9563 2.88237 13.0437 2.88237 15.0496 3.35288C17.827 4.00437 19.9956 6.17301 20.6471 8.95044C21.1176 10.9563 21.1176 13.0437 20.6471 15.0496C19.9956 17.827 17.827 19.9956 15.0496 20.6471C13.0437 21.1176 10.9563 21.1176 8.95044 20.6471C6.17301 19.9956 4.00437 17.827 3.35288 15.0496C2.88237 13.0437 2.88237 10.9563 3.35288 8.95043Z"
                                  strokeWidth="1.5"/>
                            <path id="Vector_2" d="M13.7677 10.2322L10.2322 13.7678M13.7677 13.7678L10.2322 10.2322"
                                  strokeWidth="1.5" strokeLinecap="round"/>
                        </g>
                    </svg>
            }
            <p className="font-medium text-lg ml-2 mr-auto">
                {props.message}
            </p>
            <button onClick={props.handleClick} className="ml-4 py-0.5">
                <svg className="" width="24" height="24" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <g id="icon/close-small">
                        <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd"
                              d="M6.29289 6.29289C6.68342 5.90237 7.31658 5.90237 7.70711 6.29289L12 10.5858L16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L13.4142 12L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L12 13.4142L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L10.5858 12L6.29289 7.70711C5.90237 7.31658 5.90237 6.68342 6.29289 6.29289Z"/>
                    </g>
                </svg>
            </button>
        </div>
    );
};

export default Alert;