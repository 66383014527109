import React from "react";
import FlatCard from "../Components/Cards/Flat";
import group from "../Types/group.interface";
import {Button} from "../Components/Buttons";
import Placeholder from "../Components/Cards/Placeholder";

interface Props {
    skills: group[]
}

const Skills = (props: Props) => {
    const goToContact = () => {
        const contactPageYOffset = document.getElementById('contact')?.offsetTop;
        window.scrollTo({top: contactPageYOffset, behavior: 'smooth'})
    }

    return (
        <div id="skills" className="px-4 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 md:py-16 py-8">
            <div className="max-w-[18rem] flex flex-col items-start sm:h-80 h-60 m-auto p-6">
                <h1 className="text-5xl font-extrabold text-primary mb-4">My Skills</h1>
                <p className="text-primary text-lg text-left">True mastery of any skill takes time. I lay out my skills
                    in here.</p>
            </div>
            {
                props.skills.length > 0 ?
                    props.skills.map((skill) => {
                        return <FlatCard key={skill.id} group={skill}/>;
                    }) :
                    [1, 2, 3, 4].map(item => {
                        return <Placeholder key={item}/>
                    })
            }
            <div
                className="max-w-[18rem] mt-4 p-3 bg-primary text-bg-primary flex flex-col items-start mx-auto rounded">
                <div className="p-3">
                    <h1 className="text-3xl font-extrabold mb-4">Can I help you?</h1>
                    <p className="font-medium text-lg">If you have any problem in these skills, or you'd like to work
                        together on your next project, Send me a message. you can count on me.</p>
                </div>
                <Button className={"w-full mt-auto text-lg"} handleClick={goToContact} type="button" theme="seconder">Send
                    Message</Button>
            </div>
        </div>
    );
};

export default Skills;
